import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export class GoalTrackingTagViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.goal-tracking-tag';

  tag = {
    class_name: '',
    label: '',
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setTagProperties(percent: number) {
    switch (true) {
      case percent >= 80:
        this.tag.class_name = 'tag-success';
        this.tag.label = this.translate('keep_it_up');
        break;
      case (percent > 50 && percent < 80):
        this.tag.class_name = 'tag-warning';
        this.tag.label = `${this.translate('you_can_do_better')} ${percent}%`;
        break;
      default:
        this.tag.class_name = 'tag-danger';
        this.tag.label = `${this.translate('be_careful')} ${percent}%`;
        break;
    }
  }
}
